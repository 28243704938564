import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <h1>Privacy Policy</h1>

    <h2>What information we collects</h2>

  <p>"User Personal Information" is any information about one of our Users which could, alone or together with other information, personally identify them or otherwise be reasonably linked or connected with them. Information such as a username and password, an email address, a real name, an Internet protocol (IP) address, and a photograph are examples of “User Personal Information.”</p>

  <p>User Personal Information does not include aggregated, non-personally identifying information that does not identify a User or cannot otherwise be reasonably linked or connected with them. We may use such aggregated, non-personally identifying information for research purposes and to operate, analyze, improve, and optimize our Website and Service.</p>

  <h2>Registration information</h2>

  <p>We require some basic information at the time of account creation. When you create your own username and password, we ask you for a valid email address.</p>

  <h2>Profile information</h2>

  <p>You may choose to give us more information for your Account profile, such as your full name, an avatar which may include a photograph, your biography, your location, your company, and a URL to a third-party website. This information may include User Personal Information. Please note that your profile information may be visible to other Users of our Service.</p>


  <h2>Usage information</h2>

  <p>If you're accessing our Service or Website, we automatically collect the same basic information that most services collect, subject, where necessary, to your consent. This includes information about how you use the Service, such as the pages you view, the referring site, your IP address and session information, and the date and time of each request. This is information we collect from every visitor to the Website, whether they have an Account or not. This information may include User Personal information.</p>

  <h2>Cookies</h2>

  <p>As further described below, we automatically collect information from cookies (such as cookie ID and settings) to keep you logged in, to remember your preferences, to identify you and your device and to analyze your use of our service.</p>

  <h2>Device information</h2>

  <p>We may collect certain information about your device, such as its IP address, browser or client application information, language preference, operating system and application version, device type and ID, and device model and manufacturer. This information may include User Personal information.</p>

  <h2>Changes to our Privacy Statement</h2>

  <p>Although most changes are likely to be minor, GitHub may change our Privacy Statement from time to time. We will provide notification to Users of material changes to this Privacy Statement through our Website at least 30 days prior to the change taking effect by posting a notice on our home page or sending email to the primary email address specified in your account. </p>

  </Layout>
)

export default PrivacyPolicyPage
